import React, { useRef, useEffect, useState } from 'react'
import MastHead from '@components/MastHead/'
import Layout from '@components/layout'
import SEO from '@components/seo'

const IndexPage = () => {
  const [mastHeight, setMastHeight] = useState(480)

  return (
    <Layout className="homepage">
      <>
        <SEO title="Home" />
        <MastHead height={mastHeight} />
        <a href="/" className="article__back">
          Terug naar homepage
        </a>
        <section className="pt-0 section--toegankelijkheid">
          <h1 className="article__title h2">Toegankelijkheidsverklaring</h1>
          <p>Deze toegankelijkheidsverklaring is opgesteld op 27-03-2020.</p>
          <p>
            De website debaanvanhetleven.nl is opgezet volgens de
            toegankelijkheidseisen van DigiToegankelijk.nl op niveau WCAG2.1 AA.
            Voldoen aan deze standaard maakt onze website onder andere
            gebruiksvriendelijker en beter toegankelijk voor verschillende
            doelgroepen.
          </p>
          <p>Deze verklaring geldt voor de website(s):</p>
          <ol>
            <li>
              <a href="https://debaanvanhetleven.nl">
                https://debaanvanhetleven.nl
              </a>{' '}
              (hoofdwebsite)
            </li>
          </ol>
          <h4>Testresultaten</h4>
          <ol>
            <li>
              26-03-2020: uitslag{' '}
              <a
                target="_blank"
                href={'../../check_7919_auto_rapport.pdf'}
                // href="https://www.accessibility.nl/ondersteuning/inspectie/site-2019"
                rel="noreferrer noopener"
                title="bekijk de testresultaten"
              >
                handmatig onderzoek door Accessibilty.nl
              </a>
            </li>
          </ol>
          <h4>Maatregelen om de toegankelijkheid te bevorderen</h4>
          <p>
            Wij hebben de volgende maatregelen genomen om naleving van de
            toegankelijkheidseisen te bevorderen:{' '}
          </p>
          <ul>
            <li>
              <strong>Onderzoek</strong>: Onafhankelijke deskundigen toetsen
              regelmatig (onderdelen van) onze website op toegankelijkheid.
              Gevonden knelpunten lossen wij duurzaam op.
            </li>
            <li>
              <strong>Interne controle vóór publicatie</strong>: Onze redactie
              toetst vóór publicatie alle content op toegankelijkheid.
            </li>
            <li>
              <strong>Periodieke interne controle</strong>: Onze redactie voert
              periodiek tussentijdse controles uit op de content.
            </li>
            <li>
              <strong>Overig</strong>: Er worden intern testers opgeleid.
            </li>
          </ul>
          <h4>Probleem met de toegankelijkheid van deze website?</h4>
          <p>
            Als u ondanks de maatregelen die wij al hebben genomen een
            toegankelijkheidsprobleem ervaart op onze website, laat het ons dan
            weten.{' '}
            <a
              href="https://www.rijksoverheid.nl/ministeries/ministerie-van-binnenlandse-zaken-en-koninkrijksrelaties/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bekijk onze contactgegevens
            </a>
            .
          </p>
        </section>
      </>
    </Layout>
  )
}

export default IndexPage
